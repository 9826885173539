










































































import Vue from "vue";
import { Status, Paging } from "@/store/utils";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "Transactions",

	computed: {
		_pagination: {
			get() {
				return this.$store.state.transactions.pagination;
			},
			set(value) {
				this.$store.dispatch("transactions/paginate", value);
				// page changed get new data
				this.$store.dispatch("transactions/getTransactions");
			}
		},
		transactions: function() {
			return this.$store.state.transactions.transactions;
		},
		totalItems: function() {
			return this.$store.state.transactions.totalItems;
		},
		status: function() {
			return this.$store.state.transactions.status;
		}
	},

	data: function() {
		return {
			headers: [
				{ text: "Transaction Id", value: "id", sortable: false },
				{ text: "Date", value: "created", type: "dateTime", sortable: false },
				{ text: "Amount", value: "amount", type: "currency", sortable: false, class: "currency" },
				{ text: "Transaction Type", value: "typeName", sortable: false },
				{ text: "Status", value: "statusName", sortable: false },
				{ text: "Reference", value: "reference", sortable: false },
				{ text: "Issued by", value: "issuedByUser", sortable: false },
				{ text: "", value: "action", sortable: false }
			],

			reviewDialog: false,
			succeededOptions: [
				{ text: "Failed", value: false },
				{ text: "Success", value: true }
			],

			txnData: {
				amount: null,
				txnId: null,
				succeeded: null,
				providerTxnId: null
			}
		};
	},

	methods: {
		isAmountPositive(item: any) {
			return item.bias === "dr";
		},
		getStatusColor(status: string) {
			let color = "";
			switch (status.toLocaleLowerCase()) {
				case "reviewing_withdrawal":
					color = "red--text";
					break;

				case "reviewing_deposit":
					color = "orange--text";
					break;
			}

			return color;
		},
		review(item: any) {
			this.txnData.txnId = item.id;
			this.reviewDialog = true;
		},
		async submit() {
			const form: any = this.$refs["transactionReview"];
			const valid = await form.validate();

			if (!valid) {
				return;
			} else {
				this.$store.dispatch("transactions/submitReview", this.txnData);

				this.reviewDialog = false;
				this.txnData = {
					amount: null,
					txnId: null,
					succeeded: null,
					providerTxnId: null
				};
				form.reset();
			}
		},
		cancelReview() {
			const form: any = this.$refs["transactionReview"];
			form.reset();
			this.reviewDialog = false;
		},
		formatDate(val: string) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		formatCurrency(val: string) {
			return Utils.formatText(val, Utils.TextType.CURRENCY);
		}
	}
});
