var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"Review Transactions"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.transactions,"item-key":"index","options":_vm._pagination,"server-items-length":_vm.totalItems,"loading":_vm.status.loading,"footer-props":{ 'items-per-page-options': [10, 25, 50, 100] }},on:{"update:options":function($event){_vm._pagination=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":"","justify-space-between":""}},[(_vm.isAmountPositive(item))?_c('span',[_vm._v(_vm._s(_vm.formatCurrency(-item.amount)))]):_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.amount)))]),_c('v-icon',{staticClass:"float-right",attrs:{"size":"30","color":_vm.isAmountPositive(item) ? 'red darken-2' : 'green darken-1'}},[_vm._v(_vm._s(_vm.isAmountPositive(item) ? "arrow_drop_down" : " arrow_drop_up"))])],1)]}},{key:"item.statusName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getStatusColor(item.statusKey)},[_vm._v(_vm._s(item.statusName))])]}},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reference ? item.reference : "-")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.review(item)}}},[_vm._v("review ")])]}}])}),_c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.reviewDialog),callback:function ($$v) {_vm.reviewDialog=$$v},expression:"reviewDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v("Review Transaction "+_vm._s(_vm.txnData.txnId))]),_c('v-card-text',[_c('v-form',{ref:"transactionReview",staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Amount","rules":[function (v) { return !!v || 'Amount is required'; }, function (v) { return /^\d+(.\d{2})?$/.test(v) || 'Format is incorrect (E.g 5.00)'; }],"autofocus":""},model:{value:(_vm.txnData.amount),callback:function ($$v) {_vm.$set(_vm.txnData, "amount", $$v)},expression:"txnData.amount"}}),_c('v-radio-group',{attrs:{"label":"Transaction Status","mandatory":true},model:{value:(_vm.txnData.succeeded),callback:function ($$v) {_vm.$set(_vm.txnData, "succeeded", $$v)},expression:"txnData.succeeded"}},_vm._l((_vm.succeededOptions),function(option){return _c('v-radio',{key:option.value,attrs:{"color":"secondary","label":option.text,"value":option.value}})}),1),(_vm.txnData.succeeded)?_c('v-text-field',{attrs:{"label":"Provider Transaction ID","rules":[function (v) { return !!v || 'Provider Transaction ID is required'; }]},model:{value:(_vm.txnData.providerTxnId),callback:function ($$v) {_vm.$set(_vm.txnData, "providerTxnId", $$v)},expression:"txnData.providerTxnId"}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancelReview()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit Review")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }